import React from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import appImage from "./images/logo1.jpg";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <img src={appImage} alt="App Image" className="app-image" />{" "}
      {/* Add image */}
      <h1>
        <b>¡ welcome to our app !</b>
      </h1>
      <div className="buttons-container">
        {/* <Link to="/holiday-reviews" className="landing-button">
          holiday reviews
        </Link> */}
        <Link to="/holiday-ideas" className="landing-button">
          travel
        </Link>
        <Link to="/films-to-watch" className="landing-button">
          films to watch
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
