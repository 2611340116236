import React, { useState, useEffect } from "react";
import PullToRefresh from "react-pull-to-refresh";
import axios from "axios";
import "./HolidayIdeas.css";
import TinyFlag from "tiny-flag-react";
import countries from "i18n-iso-countries";
import backIcon from "./images/back-arrow.svg";
import deleteIcon from "./images/garbage.svg";
import writeIcon from "./images/write.png";

const HolidayIdeas = () => {
  const [ideas, setIdeas] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [editingSection, setEditingSection] = useState(null);
  const [expandedIdeaId, setExpandedIdeaId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchIdeas();
  }, []);

  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

  const getCountryCode = (countryName) => {
    return countries.getAlpha2Code(countryName, "en") || "Unknown";
  };

  function flagURL(code) {
    return `https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/${code}.svg`;
  }

 const fetchIdeas = () => {
  axios
    .get(
      "https://ugvl2uoow7.execute-api.us-east-1.amazonaws.com/prod/holiday-ideas"
    )
    .then((response) => {
      if (Array.isArray(response.data)) {
        const now = new Date();
        const sortedIdeas = response.data
          .sort(
            (a, b) =>
              new Date(b.inbound_departure_time) -
              new Date(a.inbound_departure_time)
          )
          .map((idea) => {
            const isPastTrip = new Date(idea.inbound_departure_time) < now;
            console.log(`Trip to ${idea.city} is ${isPastTrip ? "past" : "upcoming"}. Inbound time: ${idea.inbound_departure_time}, Now: ${now.toISOString()}`);
            return {
              ...idea,
              isPastTrip
            };
          });
        setIdeas(sortedIdeas);
      } else {
        console.error("Fetched data is not an array:", response.data);
        setIdeas([]);
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

  const toggleExpandIdea = (id) => {
    if (expandedIdeaId === id) {
      setExpandedIdeaId(null);
      setModalVisible(false);
    } else {
      setExpandedIdeaId(id);
      setModalVisible(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({ ...fieldValues, [name]: value });
  };

  const handleStarChange = (event) => {
    const { value } = event.target;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 5)) {
      setFieldValues({ ...fieldValues, stars: value });
    }
  };

  const handleSaveSection = (idea, fields) => {
    const updatedIdea = { ...idea };
    fields.forEach((field) => {
      updatedIdea[field] = fieldValues[field]
        ? fieldValues[field].replace(/\n/g, "|")
        : idea[field];
    });
    axios
      .put(
        `https://ugvl2uoow7.execute-api.us-east-1.amazonaws.com/prod/holiday-ideas/${idea.id}`,
        updatedIdea
      )
      .then(() => {
        fetchIdeas();
        setEditingSection(null);
      })
      .catch((error) => console.error("Error updating idea:", error));
  };

  const handleSubmitNewIdea = () => {
    const submittedIdea = {
      city: "Default City",
      country: "Default Country",
      description: "",
      stars: null,
      cost: null,
      details: "",
      tips: "",
      image_url: "",
      favorites: "",
      spoiltbeans: "",
      outbound_origin: "",
      outbound_destination: "",
      outbound_departure_time: null,
      outbound_duration: "",
      inbound_origin: "",
      inbound_destination: "",
      inbound_departure_time: null,
      inbound_duration: "",
      cost_per_person: null,
    };

    axios
      .post(
        "https://ugvl2uoow7.execute-api.us-east-1.amazonaws.com/prod/holiday-ideas",
        submittedIdea
      )
      .then(() => {
        fetchIdeas();
      })
      .catch((error) => console.error("Error adding new idea:", error));
  };

  const handleDeleteIdea = (id) => {
    console.log("Attempting to delete idea with ID:", id);

    axios
      .delete(
        `https://ugvl2uoow7.execute-api.us-east-1.amazonaws.com/prod/holiday-ideas/${id}`
      )
      .then(() => {
        console.log("Idea deleted successfully:", id);
        window.location.reload();
      })
      .catch((error) => console.error("Error deleting idea:", error));
  };

  const handleCancelEdit = () => {
    setEditingSection(null);
    fetchIdeas();
  };

  const displayStarRating = (stars) => {
    const starIcons = [];
    for (let i = 0; i < stars; i++) {
      starIcons.push(<span key={i}>&#9733;</span>);
    }
    return starIcons;
  };

  const renderBullets = (text) => {
    if (!text) return null;
    return text.split("|").map((item, index) => <li key={index}>{item}</li>);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate().toString().padStart(2, "0");

    return (
      <div style={{ lineHeight: "25px" }}>
        {day} {month} {year}
        <br />
        {hours}:{minutes}
      </div>
    );
  }

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(":");
    const formattedHours = parseInt(hours, 10);
    const formattedMinutes = minutes;
    return `${formattedHours}h ${formattedMinutes}`;
  }

  return (
    <div className="holiday-ideas">
      <div className="header">
        <h1>travel</h1>
      </div>
        <div>
          {!modalVisible && (
            <div className="idea-container">
              {ideas.map((idea) => (
                <div
                  className={`idea-box ${idea.isPastTrip ? "past-trip" : ""}`}
                  key={idea.id}
                  onClick={() => toggleExpandIdea(idea.id)}
                >
                  <div className="idea-summary">
                    <div className="flag-container">
                      <TinyFlag
                        country={getCountryCode(idea.country)}
                        alt="Country flag"
                        fallbackImageURL={flagURL(getCountryCode(idea.country))}
                      />
                    </div>
                    <h3 className="city">{idea.city}</h3>
                    <div className="rating">
                      <span>{displayStarRating(idea.stars)}</span>
                    </div>
                    <span className="expand-icon">
                      {expandedIdeaId === idea.id}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      {!modalVisible && !expandedIdeaId && (
        <div className="add-button-container">
          <button className="add-button" onClick={handleSubmitNewIdea}>
            +
          </button>
        </div>
      )}

      {modalVisible && expandedIdeaId && (
        <div className="modal-backdrop" onClick={() => setModalVisible(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <React.Fragment key={expandedIdeaId}>
              {ideas
                .filter((idea) => idea.id === expandedIdeaId)
                .map((idea) => (
                  <div key={idea.id} className="idea-details-summary">
                    <div
                      className="idea-summary"
                      onClick={(e) => {
                        if (!e.target.closest(".top-input, .section-edit-button, input, textarea, button")) {
                          setModalVisible(false);
                          setExpandedIdeaId(null);
                        }
                      }}
                    >
                      <div className="flag-container">
                        <TinyFlag
                          country={getCountryCode(idea.country)}
                          alt="Country flag"
                          fallbackImageURL={flagURL(getCountryCode(idea.country))}
                        />
                      </div>

                      {editingSection === "top" ? (
                        <div className="top-input">
                          <input
                            type="text"
                            name="city"
                            value={fieldValues.city || idea.city}
                            placeholder="City"
                            onChange={handleInputChange}
                            onClick={(e) => e.stopPropagation()}
                          />
                          <input
                            type="text"
                            name="country"
                            value={fieldValues.country || idea.country}
                            placeholder="Country"
                            onChange={handleInputChange}
                            onClick={(e) => e.stopPropagation()}
                          />
                          <input
                            type="number"
                            name="stars"
                            placeholder="Rating"
                            value={fieldValues.stars || ""}
                            onChange={handleStarChange}
                            onClick={(e) => e.stopPropagation()}
                          />
                          <br></br>
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); 
                              handleSaveSection(idea, [
                                "city",
                                "country",
                                "stars",
                              ]);
                            }}
                          >
                            Done
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); 
                              setEditingSection(null);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <>
                          <h3 className="city">{idea.city}</h3>
                          <div className="rating">
                            <span>{displayStarRating(idea.stars)}</span>
                          </div>
                          <button
                            className="section-edit-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingSection("top");
                            }}
                          >
                            <img
                              src={writeIcon}
                              alt="edit Image"
                              className="section-edit-button"
                            />
                          </button>
                        </>
                      )}
                    </div>

                    <div className="idea-details">
                      <div className="sub-heading-flight">
                        <h2>
                          | Transport{" "}
                          <button
                            className="section-edit-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingSection("flights");
                            }}
                          >
                            <img
                              src={writeIcon}
                              alt="edit Image"
                              className="section-edit-button"
                            />
                          </button>
                        </h2>

                        {editingSection === "flights" ? (
                          <ul style={{ listStyleType: "none" }}>
                            <li style={{ margin: "15px 0", lineHeight: "25px" }}>
                              <b style={{ color: "rgb(161, 159, 157)" }}>
                                ⛱ {"‎"}
                              </b>{" "}
                              <input
                                type="text"
                                name="outbound_origin"
                                value={
                                  fieldValues.outbound_origin ||
                                  idea.outbound_origin
                                }
                                onChange={handleInputChange}
                                onClick={(e) => e.stopPropagation()} 
                              />
                              <br></br>
                              <b> ➔ </b>
                              <input
                                type="text"
                                name="outbound_destination"
                                value={
                                  fieldValues.outbound_destination ||
                                  idea.outbound_destination
                                }
                                onChange={handleInputChange}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </li>
                            <li style={{ margin: "15px 0" }}>
                              <input
                                type="datetime-local"
                                name="outbound_departure_time"
                                value={
                                  fieldValues.outbound_departure_time ||
                                  idea.outbound_departure_time
                                }
                                onChange={handleInputChange}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </li>
                            <li
                              style={{
                                margin: "15px 0",
                                color: "rgb(161 159 157)",
                              }}
                            >
                              <input
                                type="time"
                                name="outbound_duration"
                                value={
                                  fieldValues.outbound_duration ||
                                  idea.outbound_duration
                                }
                                onChange={handleInputChange}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </li>
                            <li
                              style={{
                                margin: "15px 0",
                                color: "#fff",
                              }}
                            >
                              _
                            </li>
                          </ul>
                        ) : (
                          <ul style={{ listStyleType: "none" }}>
                            <li style={{ margin: "15px 0", lineHeight: "25px" }}>
                              <b style={{ color: "rgb(161, 159, 157)" }}>
                                ⛱ {"‎"}
                              </b>{" "}
                              <b>{idea.outbound_origin}</b> ➔{" "}
                              {idea.outbound_destination}
                            </li>
                            <li style={{ margin: "15px 0" }}>
                              {formatDate(idea.outbound_departure_time)}{" "}
                            </li>
                            <li
                              style={{
                                margin: "15px 0",
                                color: "rgb(161 159 157)",
                              }}
                            >
                              ( {formatTime(idea.outbound_duration)}
                              {"m"} )
                            </li>
                            <li
                              style={{
                                margin: "15px 0",
                                color: "#fff",
                              }}
                            >
                              _
                            </li>
                          </ul>
                        )}

                        {editingSection === "flights" ? (
                          <>
                            <ul style={{ listStyleType: "none" }}>
                              <li
                                style={{ margin: "15px 0", lineHeight: "25px" }}
                              >
                                <b style={{ color: "rgb(161, 159, 157)" }}>
                                  🏠︎{"‎"}
                                </b>{" "}
                                <input
                                  type="text"
                                  name="inbound_origin"
                                  value={
                                    fieldValues.inbound_origin ||
                                    idea.inbound_origin
                                  }
                                  onChange={handleInputChange}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <br></br>
                                <b> ➔ </b>
                                <input
                                  type="text"
                                  name="inbound_destination"
                                  value={
                                    fieldValues.inbound_destination ||
                                    idea.inbound_destination
                                  }
                                  onChange={handleInputChange}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </li>
                              <li style={{ margin: "15px 0" }}>
                                <input
                                  type="datetime-local"
                                  name="inbound_departure_time"
                                  value={
                                    fieldValues.inbound_departure_time ||
                                    idea.inbound_departure_time
                                  }
                                  onChange={handleInputChange}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </li>
                              <li
                                style={{
                                  margin: "15px 0",
                                  color: "rgb(161 159 157)",
                                }}
                              >
                                <input
                                  type="time"
                                  name="inbound_duration"
                                  value={
                                    fieldValues.inbound_duration ||
                                    idea.inbound_duration
                                  }
                                  onChange={handleInputChange}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </li>
                            </ul>
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); 
                                handleSaveSection(idea, [
                                  "outbound_origin",
                                  "outbound_destination",
                                  "outbound_departure_time",
                                  "outbound_duration",
                                  "inbound_origin",
                                  "inbound_destination",
                                  "inbound_departure_time",
                                  "inbound_duration",
                                ]);
                              }}
                            >
                              Done
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); 
                                setEditingSection(null);
                              }}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <ul style={{ listStyleType: "none" }}>
                            <li
                              style={{
                                margin: "15px 0",
                                lineHeight: "25px",
                              }}
                            >
                              <b style={{ color: "rgb(161, 159, 157)" }}>
                                🏠︎{"‎"}
                              </b>{" "}
                              <b>{idea.inbound_origin}</b> ➔{" "}
                              {idea.inbound_destination}
                            </li>
                            <li style={{ margin: "15px 0" }}>
                              {formatDate(idea.inbound_departure_time)}
                            </li>
                            <li
                              style={{
                                margin: "15px 0",
                                color: "rgb(161 159 157)",
                              }}
                            >
                              ( {formatTime(idea.inbound_duration)}
                              {"m"} )
                            </li>
                          </ul>
                        )}
                      </div>

                      <div className="sub-heading-purp">
                        <h2>
                          | Tips
                          <button
                            className="section-edit-button"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              setEditingSection("tips");
                            }}
                          >
                            <img
                              src={writeIcon}
                              alt="edit Image"
                              className="section-edit-button"
                            />
                          </button>
                        </h2>
                        <ul>
                          <li>
                            {editingSection === "tips" ? (
                              <>
                                <textarea
                                  name="tips"
                                  className="large-textarea"
                                  value={
                                    fieldValues.tips ||
                                    idea.tips.replace(/\|/g, "\n")
                                  }
                                  onChange={handleInputChange}
                                  onClick={(e) => e.stopPropagation()} 
                                />
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    handleSaveSection(idea, ["tips"]);
                                  }}
                                >
                                  Done
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    setEditingSection(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>{renderBullets(idea.tips)}</>
                            )}
                          </li>
                        </ul>
                      </div>

                      <div className="sub-heading-blue">
                        <h2>
                          | Favorites
                          <button
                            className="section-edit-button"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              setEditingSection("favorites");
                            }}
                          >
                            <img
                              src={writeIcon}
                              alt="edit Image"
                              className="section-edit-button"
                            />
                          </button>
                        </h2>
                        <ul>
                          <li>
                            {editingSection === "favorites" ? (
                              <>
                                <textarea
                                  name="favorites"
                                  className="large-textarea"
                                  value={
                                    fieldValues.favorites ||
                                    idea.favorites.replace(/\|/g, "\n")
                                  }
                                  onChange={handleInputChange}
                                  onClick={(e) => e.stopPropagation()} 
                                />
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    handleSaveSection(idea, ["favorites"]);
                                  }}
                                >
                                  Done
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    setEditingSection(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>{renderBullets(idea.favorites)}</>
                            )}
                          </li>
                        </ul>
                      </div>

                      <div className="sub-heading-blue bottom-line">
                        <h2>
                          | Spoilt beans
                          <button
                            className="section-edit-button"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              setEditingSection("spoiltbeans");
                            }}
                          >
                            <img
                              src={writeIcon}
                              alt="edit Image"
                              className="section-edit-button"
                            />
                          </button>
                        </h2>
                        <ul>
                          <li>
                            {editingSection === "spoiltbeans" ? (
                              <>
                                <textarea
                                  name="spoiltbeans"
                                  className="large-textarea"
                                  value={
                                    fieldValues.spoiltbeans ||
                                    idea.spoiltbeans.replace(/\|/g, "\n")
                                  }
                                  onChange={handleInputChange}
                                  onClick={(e) => e.stopPropagation()} 
                                />
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    handleSaveSection(idea, ["spoiltbeans"]);
                                  }}
                                >
                                  Done
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    setEditingSection(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>{renderBullets(idea.spoiltbeans)}</>
                            )}
                          </li>
                        </ul>
                        <p className="cost-per-person">
                          each:{" "}
                          {editingSection === "cost" ? (
                            <>
                              <input
                                type="text"
                                name="cost_per_person"
                                value={
                                  fieldValues.cost_per_person ||
                                  idea.cost_per_person
                                }
                                onChange={handleInputChange}
                                onClick={(e) => e.stopPropagation()}
                              />
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  handleSaveSection(idea, ["cost_per_person"]);
                                }}
                              >
                                Done
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  setEditingSection(null);
                                }}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <>
                              <b>£{idea.cost_per_person}</b>
                              <button
                                className="section-edit-button"
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  setEditingSection("cost");
                                }}
                              >
                                <img
                                  src={writeIcon}
                                  alt="edit Image"
                                  className="section-edit-button"
                                />
                              </button>
                            </>
                          )}
                        </p>
                      </div>

                      <div className="form-buttons">
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); 
                            setModalVisible(false);
                            setExpandedIdeaId(null);
                          }}
                          className="form-button"
                        >
                          <img
                            src={backIcon}
                            alt="back Image"
                            className="form-bottom-button"
                          />
                        </button>
                        <button
                          className="form-button"
                          onClick={(e) => {
                            e.stopPropagation(); 
                            handleDeleteIdea(idea.id);
                          }}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete Image"
                            className="form-bottom-button"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </React.Fragment>
          </div>
        </div>
      )}
    </div>
  );
};

export default HolidayIdeas;
