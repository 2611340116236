import React, { useEffect, useState } from 'react'
import axios from 'axios'

function App() {
  const [data, setData] = useState(null)

  useEffect(() => {
    // Use axios to make a GET request to the local server.
    axios
      .get('https://glittering-gaufre-956229.netlify.app/holiday-ideas-data')
      .then((response) => setData(response.data))
      .catch((error) => console.error('Error fetching data:', error))
  }, [])

  return (
    <div>
      {data ? (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  )
}

export default App
